import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import Image from "../assets/images/rent.png";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function RentPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.RENT}>
      <Seo
        title="Rent | Robotic Imaging"
        description="Construction Imaging, LiDAR Scanning, Drone Photography, and other imaging services. Great service and competitive prices"
      />
      <div
        style={{
          width: "100%",
          marginTop: "60px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={Image}
          alt="Robotic Imaging Camera Rent"
          style={{ width: "100%", maxWidth: "1024px" }}
        />
      </div>
    </SiteWrapper>
  );
}
